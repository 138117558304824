class Auth {
    constructor() {
      this.authenticated = false;  
      // this.globalwebhost = "http://mol.spcmcph.com:81";    
      // this.globalwebhost = "https://spcmcph.com";    
      this.globalwebhost = "https://api.spcmc.online";    
    }
    login(cb, password) {
        if (password !== "spcmcmolec"){
            alert("Login Error: Invalid username/password")
            this.authenticated = false;
          } else{
            this.authenticated = true;      
          }
        cb();
    }
  
    logout(cb) {
      this.authenticated = false
      cb();
    }
    isAuthenticated() {
      return this.authenticated;
    }
  }  
  export default new Auth();
  