import React from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import Mdashboard from "./pages/Mdashboard";
import Encodetarget from "./pages/Encodetarget";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Maindashboard2 from "./pages/Maindash";

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/login" component={Login} exact={true} />

        <ProtectedRoute
          path="/mdashboard"
          component={Mdashboard}
          exact={true}
        />

        <ProtectedRoute
          path="/entarget"
          component={Encodetarget}
          exact={true}
        />

        <ProtectedRoute
          path="/maindash2"
          component={Maindashboard2}
          exact={true}
        />
        <Route exact path="/" render={() => <Redirect to="/login" />} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
