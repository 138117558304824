import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonItem,
  IonLabel,
  IonButton,
  IonInput,
  IonDatetime,
  IonCard,
  IonIcon,
  IonCheckbox,
  IonText,
} from "@ionic/react";

import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { refreshOutline, saveOutline } from "ionicons/icons";
import Moment from "moment";
import { numberFormat } from "../components/NumberFormat";
import Auth from "../components/Auth";
import moment from "moment";

const Encodetarget: React.FC<any> = () => {
  const [isupdate, setisupdate] = useState(false);
  const year1 = moment().add("years", 1).format("YYYY");

  const addyear = year1 + "-05-31";

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      philhealth: "",
      guarantor: "",
      cash: "",
      datefrom: "",
      dateto: "",
    },
  });

  /**
   *
   * @param data
   *
   *
   */

  const defaultjson = {
    philhealth: "",
    guarantor: "",
    cash: "",
    datefrom: "",
    dateto: "",
  };

  const onSubmit = (data: any) => {
    loaddata(data);

    if (isupdate === true) {
      alert("Successfully Updated");
    } else {
      alert("Invalid Response !!!");
    }
  };

  function loaddata(data: any) {
   fetch(Auth.globalwebhost + "/molecapp/entarget", {
    //fetch("http://localhost/MolecReport/entarget.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        philhealth: Number(data.philhealth.replace(/[^0-9\.-]+/g, "")),
        guarantor: Number(data.guarantor.replace(/[^0-9\.-]+/g, "")),
        cash: Number(data.cash.replace(/[^0-9\.-]+/g, "")),
        datefrom: Moment(data.datefrom).format("YYYY-MM-DD"),
        dateto: Moment(data.dateto).format("YYYY-MM-DD"),
        isupdate: isupdate,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setValue("philhealth", numberFormat(responseJson.ptarget));
        setValue("guarantor", numberFormat(responseJson.gtarget));
        setValue("cash", numberFormat(responseJson.ctarget));
        setValue("datefrom", responseJson.datefrom);
        setValue("dateto", responseJson.dateto);
      });
  }
  useEffect(() => {
    loaddata(defaultjson);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/login" />
          </IonButtons>
          <IonTitle>Encode Target</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonCard>
            <IonItem>
              <IonCheckbox
                color="dark"
                checked={isupdate}
                onIonChange={(e) => setisupdate(e.detail.checked)}
                slot="start"
              ></IonCheckbox>
              <IonText>
                <b>Update Target?</b>
              </IonText>
            </IonItem>

            <IonItem>
              <IonLabel>Philhealth Target: </IonLabel>
              <IonInput
                {...register("philhealth", {
                  required: "Philhealth target is a required field",
                  pattern: {
                    value: /[0-9.,]/,
                    message: "Invalid Value",
                  },
                })}
              />
            </IonItem>
            <ErrorMessage
              errors={errors}
              name="philhealth"
              as={<div style={{ color: "red" }} />}
            />

            <IonItem>
              <IonLabel>Guarantor Target: </IonLabel>
              <IonInput
                {...register("guarantor", {
                  required: "Guarantor target is a required field",
                  pattern: {
                    value: /[0-9.,]/,
                    message: "Invalid Value",
                  },
                })}
              />
            </IonItem>
            <ErrorMessage
              errors={errors}
              name="guarantor"
              as={<div style={{ color: "red" }} />}
            />

            <IonItem>
              <IonLabel>Cash Target: </IonLabel>
              <IonInput
                {...register("cash", {
                  required: "Cash target is a required field",
                  pattern: {
                    value: /[0-9.,]/,
                    message: "Invalid Value",
                  },
                })}
              />
            </IonItem>
            <ErrorMessage
              errors={errors}
              name="cash"
              as={<div style={{ color: "red" }} />}
            />

            <IonItem>
              <IonLabel>Date from:</IonLabel>
              <IonDatetime
                displayFormat="YYYY-MM-DD"
                {...register("datefrom", { required: "must pick date" })}
              />
            </IonItem>

            <IonItem>
              <IonLabel>Date to:</IonLabel>
              <IonDatetime
                max={addyear}
                displayFormat="YYYY-MM-DD"
                {...register("dateto", { required: "must pick date" })}
              />
            </IonItem>
            <div>
              {/* <IonButton type="submit">submit</IonButton> */}

              <IonButton type="submit" color="success">
                Submit
                <IonIcon slot="start" icon={saveOutline} />
              </IonButton>
            </div>
          </IonCard>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Encodetarget;
