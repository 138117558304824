import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonButtons,
  IonBackButton,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";
import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from "@ionic/core";
import Auth from "../components/Auth";
import { Bar } from "react-chartjs-2";
interface Message {
  PK_moldata: number;
  mdate: string;
  no_tests: number;
  no_received: number;
  no_results: number;
  no_results_ondate: number;
  total_cash: string;
  no_cash: number;
  total_gntr: string;
  no_gntr: number;
  total_phic: string;
  no_phic: number;
  total_noclass: number;
}

interface Message1 {
  PK_moldata: number;
  totalcash: number;
  totalgntr: number;
  totalphic: number;
  notest: number;
  noresults: number;
  totalprocess: number;
}

interface chartd {
  PK_moldata: number;
  mdate: string;
  no_tests: number;
  no_received: number;
  no_results: number;
  no_results_ondate: number;
  total_cash: string;
  no_cash: number;
  total_gntr: string;
  no_gntr: number;
  total_phic: string;
  no_phic: number;
  total_noclass: number;
}

const columns = [
  {
    name: "Date",
    selector: "mdate",
    sortable: true,
    wrap: true,
  },
  {
    name: "# Registered",
    selector: "no_tests",
    sortable: true,
    right: true,
    wrap: true,
    
  },
  {
    name: "# Test",
    selector: "no_test",
    sortable: true,
    right: true,
    wrap: true,
  },
  {
    name: "# Result",
    selector: "no_results",
    sortable: true,
    right: true,
    wrap: true,
  },

  {
    name: "# Result on date",
    selector: "no_resultondate",
    sortable: true,
    right: true,
    wrap: true,
  },
  {
    name: "Total Cash",
    selector: "total_cash",
    sortable: true,
    right: true,
  },
  {
    name: "Total Guarantor",
    selector: "total_gntr",
    sortable: true,
    right: true,
  },

  {
    name: "Total Philhealth",
    selector: "total_phic",
    sortable: true,
    right: true,
  },

  {
    name: "# Cash",
    selector: "no_cash",
    sortable: true,
    right: true,
  },

  {
    name: "# Guarantor",
    selector: "no_gntr",
    sortable: true,
    right: true,
  },

  {
    name: "# Philhealth",
    selector: "no_phic",
    sortable: true,
    right: true,
  },

  {
    name: "# Processing",
    selector: "total_noclass",
    sortable: true,
    right: true,
  },
];

const Maindashboard: React.FC<any> = ({ history }) => {
  const [DataSource, setDataSource] = useState<Message[]>([]);
  const [DataSource1, setDataSource1] = useState<Message1[]>([]);
  const [Datachart, setDataChart] = useState<chartd[]>([]);
  const customStyles = {
    rows: {
      style: {
        minHeight: "55px", // override the row height
        fontSize: "9",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "8",
        fontWeight: "Bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  async function fetchall() {
    const res = await fetch(Auth.globalwebhost + "/molecapp/molecsum");
    const data = await res.json();

    setDataSource(data);
  }

  async function fetchtotal() {
    const res = await fetch(Auth.globalwebhost + "/molecapp/summolec");
    const data = await res.json();
    setDataSource1(data);
  }

  async function fetchchartdata() {
    const res = await fetch(Auth.globalwebhost + "/molecapp/chartdata");
    const data = await res.json();
    setDataChart(data);
  }

  useEffect(() => {
    fetchall();
    fetchtotal();
    fetchchartdata();
  }, []);


  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      fetchall();
      fetchtotal();
      fetchchartdata();
      event.detail.complete();
    }, 2000);
  }

  const chartDataConfig = {
    labels: Datachart.map((d) => d.mdate),
    datasets: [
      {
        label: "# of Cash",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        // stack: 1,
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: Datachart.map((d) => d.no_cash),
      },
      {
        label: "# of Guarantor",
        backgroundColor: "rgba(106,90,205,0.2)",
        borderColor: "rgba(106,90,205,0.2)",
        borderWidth: 1,
        // stack: 1,
        hoverBackgroundColor: "rgba(106,90,205,0.2)",
        hoverBorderColor: "rgba(106,90,205,0.2)",
        data: Datachart.map((d) => d.no_gntr),
      },
      {
        label: "# of Philhealth",
        backgroundColor: "rgba(255,99,71,0.2)",
        borderColor: "rgba(255,99,71,0.2)",
        borderWidth: 1,
        // stack: 1,
        hoverBackgroundColor: "rgba(255,99,71,0.2)",
        hoverBorderColor: "rgba(255,99,71,0.2)",
        data: Datachart.map((d) => d.no_phic),
      },
    ]    
  };

  const options = {
    title: {
      display: true,
      text: "Your Total Cash, Total Guarantor and Total Philhealth in the last 15 days"
    },
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         suggestedMin: 0,
    //         suggestedMax: 70
    //       }
    //     }
    //   ]
    // }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/login" />
          </IonButtons>
          <IonTitle>Detail Summary Report</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid>
          {DataSource1.map((m) => (
            <IonList key={m.PK_moldata}>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel>Total Number Registered: {m.notest}</IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonLabel>Total Number Test Conducted: 0</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Total Number Results on Date: 0</IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonLabel>Total Number Results: {m.noresults}</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      Total Number Inprocess: {m.totalprocess}
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      Total Cash: {Number(m.totalcash).toLocaleString()}
                    </IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonLabel>
                      Total Guarantor: {Number(m.totalgntr).toLocaleString()}
                    </IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonLabel>
                      Total Philhealth: {Number(m.totalphic).toLocaleString()}
                    </IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonLabel>
                      Grand Total:
                      {(
                        Number(m.totalphic) +
                        Number(m.totalgntr) +
                        Number(m.totalcash)
                      ).toLocaleString()}
                    </IonLabel>
                  </IonItem>
                </IonCol>

                <IonCol>
                  <Bar data={chartDataConfig} options={options}/>
                </IonCol>
              </IonRow>
            </IonList>
          ))}

          <IonRow className="ion-padding">
            <IonCol>
              <DataTable
                data={DataSource}
                columns={columns}
                pagination={true}
                customStyles={customStyles}
                noHeader={true}
                striped={true}
                paginationRowsPerPageOptions= {[10,50,100]}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Maindashboard;
