import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from "@ionic/react";
import { logIn } from "ionicons/icons";
import React, { useState } from "react";
import "./Login.css";
import Auth from "../components/Auth";
import { Redirect } from "react-router-dom";

const Login: React.FC<any> = ({ history }) => {
  const [password, setPassword] = useState("");

  const UserLoginFunction = () => {
    Auth.login(UserLoginCallback, password);
  };

  const UserLoginCallback = () => {
    if (Auth.authenticated === true) {
      history.replace({
        pathname: `/maindash2`,
      });
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonTitle>Molecular Summary Report</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="maingrid ">
          <IonRow>
            <IonCol>
              <img
                src="assets/icon/logo.png"
                alt="ion"
                className="imgcontainer"
              ></img>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem color="light" className="itemlistyle">
                <IonLabel position="floating">Password</IonLabel>
                <IonInput
                     color="dark"
                  name="password"
                  type="password"
                  onIonChange={(e: any) => setPassword(e.target.value)}
                  clearInput
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton
                shape="round"
                type="submit"
                color="success"
                onClick={UserLoginFunction}
              >
                Login
                <IonIcon slot="start" icon={logIn} />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
