import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonItem,
  IonLabel,
  IonText,
  IonButton,
  IonDatetime,
  IonCard,
  IonIcon,
  IonCol,
  IonRow,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonProgressBar,
} from "@ionic/react";

import React, { useState, useEffect } from "react";
import {
  cardOutline,
  cashOutline,
  folderOpenOutline,
  medkitOutline,
  refreshOutline,
} from "ionicons/icons";
import { numberFormat } from "../components/NumberFormat";
import Moment from "moment";
import Auth from "../components/Auth";

const Maindash: React.FC<any> = ({ history }) => {
  const Datenow = Moment(new Date()).format("YYYY-MM-DD");

  const [ctarget, setctarget] = useState(0);
  const [gtarget, setgtarget] = useState(0);
  const [ptarget, setptarget] = useState(0);

  const [total_cash, settotal_cash] = useState(0);
  const [total_phic, settotal_phic] = useState(0);
  const [total_gntr, settotal_gntr] = useState(0);
  const [datefrom, setdatefrom] = useState(Datenow);
  const [dateto, setdateto] = useState(Datenow);

  const [dateserve, setdateserve] = useState(Datenow);
  const [totalserve, settotalserve] = useState(0);
  const [totalpending, setotalpending] = useState(0);

  function loaddata() {
    fetch(Auth.globalwebhost + "/molecapp/loadcurrdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setctarget(responseJson.ctarget);
        setptarget(responseJson.ptarget);
        setgtarget(responseJson.gtarget);
        settotal_cash(responseJson.total_cash);
        settotal_phic(responseJson.total_phic);
        settotal_gntr(responseJson.total_gntr);
        setdatefrom(responseJson.datefrom);
        setdateto(responseJson.dateto);
      });
  }

  function loadserve() {
    fetch(Auth.globalwebhost + "/molecapp/loadservenew", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data1: Moment(dateserve).format("YYYY-MM-DD"),
      }),
    })
      .then((response) => response.json())
      .then((responseJson1) => {
        setotalpending(responseJson1.total_pending);
        settotalserve(responseJson1.total_serve);
      })
      .catch((error) => {
        alert(error);
      });
  }

  useEffect(() => {
    loaddata();
    loadserve();
    //alert(numberFormat(50000));
  }, []);

  function compute(actual: any, target: any) {
    return actual / target;
  }

  function reloaddata() {
    loaddata();
    loadserve();
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonButtons slot="start"></IonButtons>
          <IonTitle>Main Dashboard </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonCard>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonRow>
                    <IonCol>
                      <IonCardTitle>
                        {" "}
                        Targets: From {datefrom} to {dateto}
                      </IonCardTitle>
                    </IonCol>
                    <IonButton
                      shape="round"
                      type="submit"
                      color="success"
                      onClick={reloaddata}
                    >
                      Refresh
                      <IonIcon slot="start" icon={refreshOutline} />
                    </IonButton>

                    <IonButton
                      shape="round"
                      type="submit"
                      color="medium"
                      routerLink="/entarget"
                    >
                      Edit Target
                      <IonIcon slot="start" icon={medkitOutline} />
                    </IonButton>

                
                    <IonButton
                      shape="round"
                      type="submit"
                      color="warning"
                      routerLink="/mdashboard"
                    >
                      Full Details
                      <IonIcon slot="start" icon={folderOpenOutline} />
                    </IonButton>

                   
                  </IonRow>
                </IonCardHeader>

                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel>Philhealth: {numberFormat(ptarget)} </IonLabel>
                      <IonIcon slot="start" icon={medkitOutline} />
                    </IonItem>
                    <IonItem>
                      <IonLabel>Guarantor: {numberFormat(gtarget)}</IonLabel>
                      <IonIcon slot="start" icon={cardOutline} />
                    </IonItem>
                    <IonItem>
                      <IonLabel>Cash: {numberFormat(ctarget)} </IonLabel>
                      <IonIcon slot="start" icon={cashOutline} />
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonList>
                      <IonLabel>
                        Philhealth at{" "}
                        {(compute(total_phic, ptarget) * 100).toFixed(2)} % of
                        the target
                      </IonLabel>

                      <IonProgressBar
                        value={compute(total_phic, ptarget)}
                      ></IonProgressBar>
                    </IonList>
                    <IonList>
                      <IonLabel>
                        Guarantor at{" "}
                        {(compute(total_gntr, gtarget) * 100).toFixed(2)} % of
                        the target
                      </IonLabel>

                      <IonProgressBar
                        value={compute(total_gntr, gtarget)}
                      ></IonProgressBar>
                    </IonList>

                    <IonList>
                      <IonLabel>
                        Cash at{" "}
                        {(compute(total_cash, ctarget) * 100).toFixed(2)} % of
                        the target
                      </IonLabel>

                      <IonProgressBar
                        value={compute(total_cash, ctarget)}
                      ></IonProgressBar>
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
                  <IonCardTitle>Actual:</IonCardTitle>
                </IonCardHeader>

                <IonItem>
                  <IonLabel>Philhealth: {numberFormat(total_phic)} </IonLabel>
                  <IonIcon slot="start" icon={medkitOutline} />
                </IonItem>
                <IonItem>
                  <IonLabel>Guarantor: {numberFormat(total_gntr)} </IonLabel>
                  <IonIcon slot="start" icon={cardOutline} />
                </IonItem>
                <IonItem>
                  <IonLabel>Cash {numberFormat(total_cash)}</IonLabel>
                  <IonIcon slot="start" icon={cashOutline} />
                </IonItem>
              </IonCard>
            </IonCol>

            <IonCol>
              <IonCard>
                <IonCardHeader>
                  {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
                  <IonCardTitle>
                    <IonItem>
                      <IonLabel>
                        <b>
                          Date<IonText color="danger">*</IonText>
                        </b>
                      </IonLabel>
                      <IonDatetime
                        displayFormat="YYYY-MM-DD"
                        value={dateserve}
                        onIonChange={(e) =>
                          setdateserve(
                            Moment(new Date(e.detail.value!)).format(
                              "YYYY-MM-DD"
                            )
                          )
                        }
                      ></IonDatetime>
                      <IonButton
                        shape="round"
                        type="submit"
                        color="success"
                        onClick={loadserve}
                      >
                        Load
                        <IonIcon slot="start" icon={refreshOutline} />
                      </IonButton>
                    </IonItem>
                  </IonCardTitle>
                </IonCardHeader>
                <IonItem>
                  <IonLabel>Pending: {totalpending} </IonLabel>
                  <IonIcon slot="start" icon={medkitOutline} />
                </IonItem>
                <IonItem>
                  <IonLabel>Served: {totalserve} </IonLabel>
                  <IonIcon slot="start" icon={cardOutline} />
                </IonItem>
                <IonItem>
                  <IonLabel>
                    Total: {Number(totalpending) + Number(totalserve)}{" "}
                  </IonLabel>
                  <IonIcon slot="start" icon={cashOutline} />
                </IonItem>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Maindash;
